import React, { useState, useContext, useEffect } from "react";
import { FaWindowClose } from "react-icons/fa";
import MenuContext from "../../MenuContext";
import Modal from "react-bootstrap/Modal";

function MenuList(props) {
  const menuContext = useContext(MenuContext);

  const [listedMenuCategories, setListedMenuCategories] = useState([]);

  async function closeMenuList() {
    props.setOpenMenuList(false);
  }

  async function selectCategory(menuName, category, menuId, subCat) {
    props.setOpenMenuList(false);

    props.setMenuId(menuId);
    props.setMenuName(menuName);
    props.setCategoryName(category);
    props.setSubCategoryName(subCat.subCategoryName);

    //set active category
    var alreadyActiveCategory = document.querySelector(
      ".menu_category_button.Active"
    );
    if (alreadyActiveCategory) {
      alreadyActiveCategory.classList.remove("Active");
    }
    var cat = category.replace(/\s/g, "") + "Button";
    var setActiveCategory = document.getElementById(cat);
    if (setActiveCategory) {
      setActiveCategory.classList.add("Active");
    }

    //set active subcategory
    if (subCat) {
      var alreadyActiveSubCategory = document.querySelector(
        ".subcategory.Active"
      );
      if (alreadyActiveSubCategory) {
        alreadyActiveSubCategory.classList.remove("Active");
      }

      var subCategory = subCat.subCategoryName.replace(/\s/g, "") + "Button";
      var setActiveSubCategory = document.getElementById(subCategory);
      if (setActiveSubCategory) {
        setActiveSubCategory.classList.add("Active");
      }
    }
  }

  useEffect(() => {
    // Create a flat array containing both menu names and category names for menus with more than one category
    const flatList = Object.keys(menuContext.categories).reduce(
      (result, menu) => {
        const categories = menuContext.categories[menu];

        for (var aa = 0; aa < categories.length; aa++) {
          if (categories[aa].subcategories.length > 0) {
            var obj = {
              menuId: categories[aa].menuId,
              menuName: categories[aa].menuName,
              category: categories[aa].category,
              subCategories: categories[aa].subcategories,
            };
            result.push(obj);
          } else {
            var obj2 = {
              menuId: categories[aa].menuId,
              menuName: categories[aa].menuName,
              category: categories[aa].category,
              subCategories: categories[aa].subcategories,
            };
            result.push(obj2);
          }
        }

        return result;
      },
      []
    );
    // Set the state with the new flatList
    setListedMenuCategories(flatList);
  }, [menuContext.categories]);

  return (
    <div>
      <Modal className="addressModal" show={props.openMenuList}>
        <Modal.Body>
          <Modal.Header className="p-0">
            <div className="w-100 d-flex justify-content-between">
              <h3 className="fw-bold">Menu Categories</h3>
              <FaWindowClose
                onClick={closeMenuList}
                style={{ width: "1.75em", height: "1.75em" }}
                className="text-danger"
              />
            </div>
          </Modal.Header>
          <div className="menu_list_active">
            <div className="menu_categories_list">
              {listedMenuCategories.length > 0 && (
                <>
                  {listedMenuCategories.map((cat) => (
                    <div key={cat.category} className="px-2 category_container">
                      {cat.subCategories.length < 1 ? (
                        <h5
                          className="cat_name"
                          onClick={() =>
                            selectCategory(
                              cat.menuName,
                              cat.category,
                              cat.menuId,
                              ""
                            )
                          }
                        >
                          {cat.category}
                        </h5>
                      ) : (
                        <h5 className="cat_name">{cat.category}</h5>
                      )}
                      <div className="subcats">
                        {cat.subCategories.map((subCat) => (
                          <h6
                            onClick={() =>
                              selectCategory(
                                cat.menuName,
                                cat.category,
                                cat.menuId,
                                subCat
                              )
                            }
                            className="subcat_name"
                            key={subCat.subCategoryName}
                          >
                            {subCat.subCategoryName}
                          </h6>
                        ))}
                      </div>
                    </div>
                  ))}
                </>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default MenuList;
