import React, { useContext, useEffect, useState } from "react";
import MenuContext from "../../MenuContext";

function MenuItems(props) {
  const menuContext = useContext(MenuContext);

  const [menuItems, setMenuItems] = useState([]);
  const [categoryDescription, setCategoryDescription] = useState();

  useEffect(() => {
    if (menuContext.items) {
      var menuitemsArray = [];
      for (var i = 0; i < menuContext.items.length; i++) {
        var itemName = menuContext.items[i].itemName;
        if (menuContext.items[i].itemSubCategory) {
          if (
            menuContext.items[i].itemSubCategory === props.subCategoryName &&
            !menuitemsArray.some((item) => item.itemName === itemName)
          ) {
            menuitemsArray.push(menuContext.items[i]);
          }
        } else {
          if (
            menuContext.items[i].itemCategory === props.categoryName &&
            !menuitemsArray.some((item) => item.itemName === itemName)
          ) {
            menuitemsArray.push(menuContext.items[i]);
          }
        }
      }
      setMenuItems(menuitemsArray);
    }
  }, [props]);

  async function toggleItemModal(item) {
    item.active = "Active";
    props.setSelectedItem(item);
    props.setShowItemModal(true);
  }

  let cat;
  useEffect(() => {
    if (props.menuName) {
      cat = menuContext.categories[props.menuName];
      for (var i = 0; i < cat.length; i++) {
        if (props.categoryName === cat[i].category) {
          if (cat[i].subcategories.length > 0) {
            for (var j = 0; j < cat[i].subcategories.length; j++) {
              if (props.subCategoryName === cat[i].subcategories[j].subCategoryName) {
                setCategoryDescription(cat[i].subcategories[j].subCategoryDescription);
              }
            }
          } else {
            setCategoryDescription(cat[i].categoryDescription);
          }
        }
      }
    }
  }, [props]);

  return (
    <div className="menu_items">
      {categoryDescription && (
        <div className="categoryDescription">
          {categoryDescription}
        </div>
      )}
      {menuItems?.map((i, index) => (
        <div
          key={index}
          className="card"
          onClick={() => toggleItemModal(i)}
        >
          <div className="px-4 py-2">
            <h4 className="fw-bold itemName">{i.itemName}</h4>
            {i.itemDescription && (
              <p className="mb-1 itemDescription"><em>{i.itemDescription}</em></p>
            )}
            <p className="mb-0 fw-bold itemPrice">{"$" + i.itemPrice.toFixed(2)}</p>
          </div>
        </div>
      ))}
    </div>
  );
}

export default MenuItems;
