import React, { useState, useEffect } from "react";
import GoogleMapsInputHome from "../../tools/GoogleMapsInputHome";
import FooterHome from "../footer/FooterHome";
import NavbarHome from "../navbar/NavbarHome";
import stores from "../../data/stores";
import { FaMapMarkerAlt } from "react-icons/fa";


function MyOrderPortalHome() {
  const [locationAvailable, setLocationAvailable] = useState(false);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [sortedStores, setSortedStores] = useState([]);
  const [searchedAddress, setSearchedAddress] = useState({
    street: "",
    city: "",
    state: "",
    zipCode: "",
    apartment: ""
  });

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          const { latitude, longitude } = position.coords;
          setLatitude(latitude);
          setLongitude(longitude);
          setLocationAvailable(true);
        },
        error => {
          console.error("Error getting location:", error);
          setLocationAvailable(false);
        }
      );
    } else {
      console.log("Geolocation is not supported by this browser.");
      setLocationAvailable(false);
    }
  }, []);

  useEffect(() => {
    if (searchedAddress.street !== "") {
      async function fetchLatLong() {
        try {
          const latlong = await getLatLong(searchedAddress);
          setLatitude(latlong.latitude);
          setLongitude(latlong.longitude);
          setLocationAvailable(true);
          setSortedStores([]);
        } catch (error) {
          console.error(error);
        }
      }
      fetchLatLong();
    }
  }, [searchedAddress]);

  async function getLatLong(searchedAddress) {
    const apiKey = 'AIzaSyC0286rXJ9WGiFAPZO8oQyWsh4M4fV0ag8'; // Replace with your Google Maps API Key
    const address = `${searchedAddress.street}, ${searchedAddress.city}, ${searchedAddress.state} ${searchedAddress.zipCode}`;

    const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${apiKey}`);
    const data = await response.json();

    if (data.status === 'OK') {
      const location = data.results[0].geometry.location;
      return {
        latitude: location.lat,
        longitude: location.lng
      };
    } else {
      throw new Error('Geocoding failed: ' + data.status);
    }
  }

  if ((sortedStores.length < 1 && locationAvailable)) {
    const sorted = stores.map(store => {
      const distance = haversineDistance(
        latitude,
        longitude,
        store.latitude,
        store.longitude
      );
      return {
        ...store,
        distance: distance.toFixed(2)
      };
    }).sort((a, b) => a.distance - b.distance);

    setSortedStores(sorted);
  }

  function haversineDistance(lat1, lon1, lat2, lon2) {
    const toRadians = angle => (angle * Math.PI) / 180;

    const R = 6371; // Radius of the Earth in kilometers
    const dLat = toRadians(lat2 - lat1);
    const dLon = toRadians(lon1 - lon2);  // Note the change in order for correct calculation

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRadians(lat1)) * Math.cos(toRadians(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distanceInKilometers = R * c; // Distance in kilometers

    const distanceInMiles = distanceInKilometers * 0.621371; // Convert to miles
    return distanceInMiles;
  }

  return (
    <>
      <NavbarHome />
      <div className="app_home">
        {locationAvailable ? (
          <div className="location_available">
            <div className="stores_container">
              <div className="maps_input">
                <GoogleMapsInputHome setSearchedAddress={setSearchedAddress} />
              </div>
              <div className="store_list">
                <h2 className="stores_header">Restaurant's Near Me</h2>
                {sortedStores.map((store, index) => (
                  <a className="order_link_container" href={store.orderLink}>
                    <div key={index} className="store">
                      <div className="store_image"><img src={store.image} alt={store.name} /></div>
                      <div className="detail_container">
                        <div className="store_details">
                          <h2 className="store_name">{store.name}</h2>
                          <div className="store_address">{store.address}</div>
                          <div className="store_distance"><FaMapMarkerAlt className="map_pin" /><div>{store.distance} mi</div></div>
                        </div>
                        <a className="order_link" href={store.orderLink}>Order Now</a>
                      </div>
                    </div>

                  </a>
                ))}
              </div>
            </div>
          </div>
        ) : (
          <div className="location_unavailable">
            <div className="top">
              <div className="left">
                <div className="home_text">
                  <div className="text">Find Local Restaurants.</div>
                  <div className="text">Place Your Order.</div>
                </div>
                <GoogleMapsInputHome setSearchedAddress={setSearchedAddress} />
              </div>
              <div className="right">
                <img className="home_image" src="https://i.ibb.co/BKvtnkt/maragrita-pizza.jpg" alt="" />
              </div>
            </div>
            <div className="bottom">
              <div className="text">My Order Portal connects restaurants with customers through a seamless online ordering experience, with lower fees than the competition.</div>
            </div>
          </div>
        )}
      </div>
      <FooterHome />
    </>
  );
}

export default MyOrderPortalHome;