export const getMenuCategories = (responseData) => {
  var menuCategories = {};

  for (var i = 0; i < responseData.length; i++) {
    var menuName = responseData[i].menuName;
    var categoryExists = false;

    // Initialize the menu's categories array if it doesn't exist
    if (!menuCategories[menuName]) {
      menuCategories[menuName] = [];
    }

    // Check if the category exists in the menu's categories array
    for (var j = 0; j < menuCategories[menuName].length; j++) {
      if (
        menuCategories[menuName][j].category === responseData[i].itemCategory
      ) {
        categoryExists = true;

        // Check and add subcategory if available
        if (
          responseData[i].itemSubCategory &&
          !menuCategories[menuName][j].subcategories.some(
            subcat => subcat.subCategoryName === responseData[i].itemSubCategory
          )
        ) {
          var subcat = {
            subCategoryName: responseData[i].itemSubCategory,
            subCategoryDescription: responseData[i].categoryDescription,
          };
          menuCategories[menuName][j].subcategories.push(subcat);
        }

        break;
      }
    }

    // If the category does not exist, create it and add subcategory if available
    if (!categoryExists) {
      var newCategory = {
        category: responseData[i].itemCategory,
        menuName: responseData[i].menuName,
        categoryDescription: responseData[i].categoryDescription,
        subcategories: [],
        menuId: responseData[i].menuID,
      };
      var subcat = {
        subCategoryName: responseData[i].itemSubCategory,
        subCategoryDescription: responseData[i].categoryDescription,
      };
      if (responseData[i].itemSubCategory) {
        newCategory.subcategories.push(subcat);
      }
      menuCategories[menuName].push(newCategory);
    }
  }
  return menuCategories;
};
