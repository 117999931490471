import React, { useEffect, useContext, useState } from "react";
import ApplicationContext from "../../ApplicationContext";
import { IoIosArrowDown } from "react-icons/io";
import { BsFillPlusCircleFill } from "react-icons/bs";
import { MdRadioButtonUnchecked } from "react-icons/md";
import { BsCheckCircleFill } from "react-icons/bs";
import axios from "axios";
import {
  APPLICATION,
  BASE_API,
  STORE_NAME_CODE,
} from "../../constants";
import {
  getNext14Days,
  getNext14DaysTimes,
} from "../../utils/address/GetNext14DaysOrderTimes";
import StoreContext from "../../StoreContext";
import GoogleMapsInput from "../../tools/GoogleMapsInput";

function OrderOptions(props) {
  const appContext = useContext(ApplicationContext);
  const storeContext = useContext(StoreContext);

  function toggleOrderOptionsExpandCollapse() {
    if (
      document.getElementById("orderType_arrow").classList.contains("collapsed")
    ) {
      document.getElementById("orderType_body").classList.remove("collapse");
      document.getElementById("orderType_arrow").classList.remove("collapsed");
    } else {
      document.getElementById("orderType_body").classList.add("collapse");
      document.getElementById("orderType_arrow").classList.add("collapsed");
    }
  }

  //ADDRESS STATE
  const [userAddresses, setUserAddresses] = useState();
  const [showAddressInput, setShowAddressInput] = useState();

  //FUTURE ORDER STATES
  const [next14Days, setNext14Days] = useState();
  const [timeSlots, setTimeSlots] = useState([]);

  const [userHasAddresses, setUserHasAddresses] = useState();

  useEffect(() => {
    if (storeContext.acceptDelivery !== "true") {
      props.setOrderType("Pickup");
      return;
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${BASE_API}api/User/GetAddressesByUser`,
          {
            headers: {
              Authorization: `Bearer ${appContext.userInfo.token}`,
              Application: APPLICATION,
            },
          }
        );
        setUserHasAddresses(true);
        setUserAddresses(response.data);
      } catch (error) {
        setShowAddressInput(true);
        setUserHasAddresses(false);
      }
    };
    if (props.orderType === "Delivery") {
      fetchData();
    }

    var timeSlots = getNext14DaysTimes(props, storeContext);
    setTimeSlots(timeSlots);

    var formattedDates = getNext14Days(storeContext);
    setNext14Days(formattedDates);
  }, [props]);

  function addAddress() {
    setShowAddressInput(!showAddressInput);
  }

  var setActiveTime;
  function orderTime(time) {
    if (time === "Now") {
      setActiveTime = document.getElementById("option_now");
      setActiveTime.classList.add("Active");
      if (
        document.getElementById("option_later").classList.contains("Active")
      ) {
        document.getElementById("option_later").classList.remove("Active");
      }
    } else if (time === "Later") {
      setActiveTime = document.getElementById("option_later");
      setActiveTime.classList.add("Active");
      if (document.getElementById("option_now").classList.contains("Active")) {
        document.getElementById("option_now").classList.remove("Active");
      }
    }
    props.setNowOrLater(time);
  }

  var setActiveOrderType;
  if (
    appContext.cart.OrderType !== null ||
    appContext.cart.OrderType !== undefined
  ) {
    setActiveOrderType = appContext.cart.OrderType;
  }
  async function orderTypeFunction(type) {
    if (storeContext.acceptDelivery !== "true") {
      props.setOrderType("Pickup");
      return;
    }
    if (type === "Pickup") {
      setActiveOrderType = document.getElementById("option_pickup");
      setActiveOrderType.classList.add("Active");
      if (
        document.getElementById("option_delivery").classList.contains("Active")
      ) {
        document.getElementById("option_delivery").classList.remove("Active");
      }
    } else if (type === "Delivery") {
      setActiveOrderType = document.querySelector(".option_delivery");
      setActiveOrderType.classList.add("Active");
      if (
        document.getElementById("option_pickup").classList.contains("Active")
      ) {
        document.getElementById("option_pickup").classList.remove("Active");
      }
    }
    props.setOrderType(type);

    var newCart = {
      CartID: appContext.cart.CartID,
      OrderType: type,
      CartPrice: appContext.cart.CartPrice,
    };
    var newAppContext = {
      userInfo: appContext.userInfo,
      cart: newCart,
      cartItems: appContext.cartItems,
    };
    props.setAppContext(newAppContext);
    localStorage.setItem("appContext", JSON.stringify(newAppContext));
  }

  // #region SELECT ADDRESS
  // async function selectAddress(i) {
  //   await props.getLatLong(i, true);
  //   props.setSelectedAddress(i);
  //   console.log(i);
  //   var newAddress = {
  //     email: appContext.userInfo.email,
  //     firstName: appContext.userInfo.firstName,
  //     lastName: appContext.userInfo.lastName,
  //     phoneNumber: appContext.userInfo.phoneNumber,
  //     role: appContext.userInfo.role,
  //     token: appContext.userInfo.token,
  //     userID: appContext.userInfo.userID,
  //     customerID: appContext.userInfo.customerID,
  //     storeName: appContext.userInfo.storeName,
  //     address: i,
  //   };
  //   var newAppContext = {
  //     userInfo: newAddress,
  //     cart: appContext.cart,
  //     cartItems: appContext.cartItems,
  //   };
  //   props.setAppContext(newAppContext);
  //   localStorage.setItem("appContext", JSON.stringify(newAppContext));
  // }
  async function selectAddress(i) {
    await props.getLatLong(i, true);
    props.setSelectedAddress(i);
    var newAddress = {
      email: appContext.userInfo.email,
      firstName: appContext.userInfo.firstName,
      lastName: appContext.userInfo.lastName,
      phoneNumber: appContext.userInfo.phoneNumber,
      role: appContext.userInfo.role,
      token: appContext.userInfo.token,
      userID: appContext.userInfo.userID,
      customerID: appContext.userInfo.customerID,
      storeName: appContext.userInfo.storeName,
      address: i,
    };
    var newAppContext = {
      userInfo: newAddress,
      cart: appContext.cart,
      cartItems: appContext.cartItems,
    };
    props.setAppContext(newAppContext);
    localStorage.setItem("appContext", JSON.stringify(newAppContext));
    const storeAddress = storeContext.address;
    const selectedAddress = `${i.streetAddress} ${i.city} ${i.stateName}`;
    const service = new window.google.maps.DistanceMatrixService();
    const request = {
      origins: [storeAddress],
      destinations: [selectedAddress],
      travelMode: window.google.maps.TravelMode.DRIVING,
    };

    service.getDistanceMatrix(request, (response, status) => {
      if (status === window.google.maps.DistanceMatrixStatus.OK) {
        const element = response.rows[0].elements[0];
        if (element.status === "OK") {
          const distanceInMeters = element.distance.value;
          const distanceInMiles = distanceInMeters / 1609.34;
          const formattedDistance = distanceInMiles.toFixed(2);
          const duration = element.duration.text;
          console.log(`Distance from store to selected address: ${formattedDistance} miles`);
          console.log(`Estimated travel time: ${duration}`);

          var fee;
          if (STORE_NAME_CODE === "frankspizzablairstown") {
            if (distanceInMiles > 8) {
              fee = storeContext.deliveryFee + 5;
              props.setDeliveryFee(fee);
              props.setIsExtraDeliveryFee(true);
            } else if (distanceInMiles > 7) {
              fee = storeContext.deliveryFee + 4;
              props.setDeliveryFee(fee);
              props.setIsExtraDeliveryFee(true);
            } else if (distanceInMiles > 6) {
              fee = storeContext.deliveryFee + 3;
              props.setDeliveryFee(fee);
              props.setIsExtraDeliveryFee(true);
            } else if (distanceInMiles > 5) {
              fee = storeContext.deliveryFee + 2;
              props.setDeliveryFee(fee);
              props.setIsExtraDeliveryFee(true);
            } else if (distanceInMiles > 4) {
              fee = storeContext.deliveryFee + 1;
              props.setDeliveryFee(fee);
              props.setIsExtraDeliveryFee(true);
            } else {
              props.setDeliveryFee(storeContext.deliveryFee);
              props.setIsExtraDeliveryFee(false);
            }
          }
          if (STORE_NAME_CODE === "jefryspizzaparsippany") {
            if (distanceInMiles > 4) {
              fee = storeContext.deliveryFee + 5;
              props.setDeliveryFee(fee);
              props.setIsExtraDeliveryFee(true);
            } else {
              props.setDeliveryFee(storeContext.deliveryFee);
              props.setIsExtraDeliveryFee(false);
            }
          }
          if (STORE_NAME_CODE === "demostore") {
            if (distanceInMiles > 4) {
              fee = storeContext.deliveryFee + 5;
              props.setDeliveryFee(fee);
              props.setIsExtraDeliveryFee(true);
            } else {
              props.setDeliveryFee(storeContext.deliveryFee);
              props.setIsExtraDeliveryFee(false);
            }
          }
        } else {
          console.log("Error with the distance request.");
        }
      } else {
        console.log("Google Maps Distance Matrix failed:", status);
      }
    });
  }
  // #endregion
  //

  function selectFutureDay(dateObject) {
    props.setSelectedDay(dateObject);
  }

  function selectTime(slot) {
    props.setSelectedTime(slot);
  }

  // Load Google Maps API script asynchronously
  const loadGoogleMapsScript = () => {
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyC0286rXJ9WGiFAPZO8oQyWsh4M4fV0ag8&libraries=places`;
    document.head.appendChild(script);
  };

  // Load Google Maps API script when component mounts
  React.useEffect(() => {
    loadGoogleMapsScript();
  }, []);

  return (
    <div className="card m-1 orderType">
      <div
        className="card-header"
        onClick={toggleOrderOptionsExpandCollapse}
        data-toggle="collapse-collapsed"
      >
        {!props.orderType ? (
          <div className="orderType_choice">
            <MdRadioButtonUnchecked className="checkbox" />
            <div className="orderType_header">Select an Order Type</div>
          </div>
        ) : (
          <div className="orderType_choice">
            {props.nowOrLater === "Later" &&
              (!props.selectedTime || !props.selectedDay) ? (
              <div className="orderType_choice">
                <MdRadioButtonUnchecked className="checkbox" />
                <div className="orderType_header">Select an Order Time</div>
              </div>
            ) : (
              <>
                {props.orderType === "Delivery" && !props.selectedAddress ? (
                  <div className="orderType_choice">
                    <MdRadioButtonUnchecked className="checkbox" />
                    <div className="orderType_header">
                      Select a Delivery Address
                    </div>
                  </div>
                ) : (
                  <div className="orderType_choice">
                    <BsCheckCircleFill className="checked" />
                    <div className="orderType_header">
                      {props.orderType + " - " + props.nowOrLater}
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        )}
        <IoIosArrowDown id="orderType_arrow" />
      </div>

      <div id="orderType_body" className="card-body">
        <div className="order_options">
          <div className="option">
            {storeContext.acceptDelivery === "true" ? (
              <>
                <button
                  id="option_pickup"
                  onClick={() => orderTypeFunction("Pickup")}
                >
                  Pickup
                </button>
                <button
                  id="option_delivery"
                  className="option_delivery"
                  onClick={() => orderTypeFunction("Delivery")}
                >
                  Delivery
                </button>
              </>
            ) : (
              <>
                <button id="option_pickup" className="Active onlyPickup">
                  Pickup
                </button>
              </>
            )}
          </div>
          <div className="option">
            <button id="option_now" onClick={() => orderTime("Now")}>
              Now
            </button>
            <button id="option_later" onClick={() => orderTime("Later")}>
              Later
            </button>
          </div>
        </div>

        {props.nowOrLater === "Later" ? (
          <div className="laterTime_container">
            {props.orderType ? (
              <div className="laterTime_header">
                {props.orderType + " Time:"}
              </div>
            ) : (
              <div className="laterTime_header">{"Order Time:"}</div>
            )}

            <div className="laterTime_choices">
              <div className="dropdown_date">
                <button
                  className="btn btn-secondary dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {props.selectedDay ? props.selectedDay.date : "Select Date"}
                </button>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton1"
                >
                  {next14Days.map((i) => (
                    <li key={i.id}>
                      <div
                        className="dropdown-item"
                        onClick={() => selectFutureDay(i)}
                      >
                        {i.date}
                      </div>
                    </li>
                  ))}
                </ul>
              </div>

              <div className="dropdown_time">
                <button
                  className="btn btn-secondary dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton2"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {props.selectedTime ? props.selectedTime.time : "Select Time"}
                </button>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton1"
                >
                  {timeSlots.map((slot) => (
                    <li key={slot.time} onClick={() => selectTime(slot)}>
                      <div className="dropdown-item">{slot.time}</div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        ) : null}

        {props.orderType === "Delivery" ? (
          <div className="addresses_container">
            {showAddressInput ? (
              <>
                <div className="add_address" onClick={addAddress}>
                  <div className="add_addressTitle">Add an Address</div>
                  <BsFillPlusCircleFill />
                </div>

                <GoogleMapsInput
                  getLatLong={props.getLatLong}
                  deliveryFee={props.deliveryFee}
                  setDeliveryFee={props.setDeliveryFee}
                  isExtraDeliveryFee={props.isExtraDeliveryFee}
                  setIsExtraDeliveryFee={props.setIsExtraDeliveryFee}
                  setShowAddressInput={setShowAddressInput}
                  setUserAddresses={setUserAddresses}
                  setSelectedAddress={props.setSelectedAddress}
                  setAppContext={props.setAppContext}
                />
                <div className="delivery_range">
                  Delivery Range: <b>{storeContext.deliveryMiles} miles</b>
                </div>
              </>
            ) : (
              <>
                <div className="add_address" onClick={addAddress}>
                  <div className="add_addressTitle">Add an Address</div>
                  <BsFillPlusCircleFill />
                </div>
              </>
            )}
            {userHasAddresses && (
              <>
                <div className="add_address">
                  <div className="add_addressTitle">
                    {props.selectedAddress ? (
                      <>Delivering To:</>
                    ) : (
                      <>Use an Existing Address</>
                    )}
                  </div>
                </div>
                <button
                  className="btn btn-secondary dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton4"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {props.selectedAddress ? (
                    <>
                      {props.selectedAddress.streetAddress +
                        " " +
                        props.selectedAddress.city +
                        ", " +
                        props.selectedAddress.stateName +
                        " " +
                        props.selectedAddress.zipCode}
                    </>
                  ) : (
                    <>Select Address</>
                  )}
                </button>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton4"
                >
                  {userAddresses?.map((i) => (
                    <li
                      id={`residence_${i.residenceID}`}
                      className={`address ${i.Active}`}
                      key={i.residenceID}
                      onClick={() => selectAddress(i)}
                    >
                      <div className="dropdown-item">
                        {i.streetAddress +
                          " " +
                          i.city +
                          ", " +
                          i.stateName +
                          " " +
                          i.zipCode}
                      </div>
                    </li>
                  ))}
                </ul>
              </>
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default OrderOptions;
