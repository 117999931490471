import React, { useContext, useState, useEffect, useRef } from "react";
import ApplicationContext from "../../ApplicationContext";
import StoreContext from "../../StoreContext";
import { AiFillPlusCircle } from "react-icons/ai";
import MxAddPaymentMethod from "./MxAddPaymentMethod";
import { toast } from "react-toastify";
import axios from "axios";
import {
  APPLICATION,
  BASE_API,
  ORDER_FEE,
  STORE_NAME_CODE,
} from "../../constants";
import { CreateGuid } from "../../utils/authenticate/CreateGUID";
import { useNavigate } from "react-router-dom";
import { getNowTime } from "../../utils/time/GetNowTime";
import ReCAPTCHA from "react-google-recaptcha";

function MxCheckoutForm(props) {
  const navigate = useNavigate();
  const appContext = useContext(ApplicationContext);
  const storeContext = useContext(StoreContext);

  const captchaRef = useRef(null);
  const [captchaToken, setCaptchaToken] = useState("");
  const handleCaptchaChange = (token) => {
    setCaptchaToken(token); // Save token to send to the backend
  };
  const resetCaptcha = () => {
    if (captchaRef.current) {
      captchaRef.current.reset(); // Reset the CAPTCHA
      setCaptchaToken(""); // Clear the token
    }
  };
  // const [sessionId] = useState(generateSessionId()); // Function to generate unique session ID
  // function generateSessionId() {
  //   return "session-" + Math.random().toString(36).substr(2, 9);
  // }
  // // const [csrfToken, setCsrfToken] = useState(null);

  const [isAddingPaymentMethod, setIsAddingPaymentMethod] = useState(true);
  const [selectedPayment, setSelectedPayment] = useState();
  const [previouslyActivePayment, setPreviouslyActivePayment] = useState();

  const [cardNumber, setCardNumber] = useState();
  const [expMonth, setExpMonth] = useState();
  const [expYear, setExpYear] = useState();
  const [cvcCode, setCvcCode] = useState();
  const newOrderId = CreateGuid();
  let orderData = {
    Items: [],
  };

  const [isDemo, setIsDemo] = useState("MxMerchant");

  useEffect(() => {
    const pathname = window.location.pathname;
    const store = pathname.split("/")[1];
    if (store === "demostore" || store === "demostore2") {
      setIsDemo("MxMerchantDemo");
    }
  }, []);

  // Fetch CSRF token from backend
  // useEffect(() => {
  //   async function fetchCsrfToken() {
  //     try {
  //       const response = await axios.get(`${BASE_API}api/MxMerchantDemo/csrf-token`, {
  //         headers: {
  //           "Session-Id": sessionId, // Include the session ID
  //         },
  //       });
  //       setCsrfToken(response.data.csrfToken);
  //     } catch (error) {
  //       console.error("Failed to get CSRF token", error);
  //     }

  //     // const response = await axios.get(`${BASE_API}api/Security/csrf-token`, {
  //     //   headers: {
  //     //     "Session-Id": sessionId,
  //     //   },
  //     // });
  //     // // Assuming the response contains the CSRF token as { csrfToken: '...' }
  //     // setCsrfToken(response.data.csrfToken);
  //   }
  //   if (!csrfToken) {
  //     fetchCsrfToken();
  //   }
  // }, []);

  useEffect(() => {
    var discountedTax = null;
    var discountedSubtotal = null;
    var discountedTotal = null;
    var tax = parseFloat(props.taxAmount);

    if (
      props.discountedSubtotal &&
      props.discountedTax &&
      props.discountedTotal
    ) {
      discountedSubtotal = props.discountedSubtotal;
      discountedTax = props.discountedTax;
      discountedTotal = parseFloat(props.discountedTotal);
    }
    orderData.OrderID = newOrderId;
    orderData.OrderStatusID = 1;
    orderData.DiscountedSubtotal = discountedSubtotal;
    orderData.DiscountedTax = discountedTax;
    orderData.DiscountedTotal = discountedTotal;
    orderData.Fee = ORDER_FEE;
    orderData.PhoneNumber = appContext.userInfo.phoneNumber;
    orderData.Email = appContext.userInfo.email;
    orderData.DeliveryFee = props.deliveryFee;
    orderData.OrderSubtotal = appContext.cart.CartPrice;
    orderData.OrderTax = tax;

    for (var a = 0; a < appContext.cartItems.length; a++) {
      var newCartItemID = CreateGuid();
      orderData.Items[a] = {
        OrderItemID: newCartItemID,
        OrderItem_MenuItemID: appContext.cartItems[a].CartItem_MenuItemID,
        ItemQuantity: appContext.cartItems[a].ItemQuantity,
        ItemTotalPrice: appContext.cartItems[a].ItemTotalPrice,
        ItemSpecialRequests: appContext.cartItems[a].ItemSpecialRequests,
        ItemName: appContext.cartItems[a].ItemName,
        ItemPrice: appContext.cartItems[a].ItemPrice,
        SizeCode: appContext.cartItems[a].SizeCode,
        OtherPrice: appContext.cartItems[a].ItemPrice,
        Extras: [],
      };
      if (appContext.cartItems[a].RewardCost) {
        orderData.Items[a].RewardPoints = appContext.cartItems[a].RewardCost;
      } else {
        orderData.Items[a].RewardPoints = 0;
      }
      if (!orderData.Items[a].SizeCode) {
        orderData.Items[a].SizeCode = null;
      }

      for (var b = 0; b < appContext.cartItems[a].Extras.length; b++) {
        if (appContext.cartItems[a].Extras[b].PizzaFirstHalf === null) {
          appContext.cartItems[a].Extras[b].PizzaFirstHalf = 0;
        }
        if (appContext.cartItems[a].Extras[b].PizzaSecondHalf === null) {
          appContext.cartItems[a].Extras[b].PizzaSecondHalf = 0;
        }
        orderData.Items[a].Extras[b] = {
          OrderExtra_OrderItemID: newCartItemID,
          OrderExtra_MenuItemExtraID:
            appContext.cartItems[a].Extras[b].CartExtra_MenuItemExtraID,
          PizzaFirstHalf: appContext.cartItems[a].Extras[b].PizzaFirstHalf,
          PizzaSecondHalf: appContext.cartItems[a].Extras[b].PizzaSecondHalf,
          PizzaHalfCode: appContext.cartItems[a].Extras[b].PizzaHalfCode,
          OrderExtraName: appContext.cartItems[a].Extras[b].ExtraName,
          ExtraPrice: appContext.cartItems[a].Extras[b].ExtraPrice,
        };
      }
    }
  }, [newOrderId]);

  useEffect(() => {
    if (
      props.customerPaymentProfiles.recordCount === 1 &&
      isAddingPaymentMethod
    ) {
      setSelectedPayment(props.customerPaymentProfiles.records[0]);
      setIsAddingPaymentMethod(false);
    } else if (
      props.customerPaymentProfiles.recordCount === 1 &&
      !isAddingPaymentMethod
    ) {
      setSelectedPayment(props.customerPaymentProfiles.records[0]);
    } else if (props.customerPaymentProfiles.recordCount > 1) {
      setIsAddingPaymentMethod(false);
    } else {
      setSelectedPayment();
    }
  }, [props.customerPaymentProfiles]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!captchaToken) {
      alert("Please complete the CAPTCHA to prove you are not a robot!");
      return;
    }

    if (document.getElementById("submit")) {
      //PREVENT Double click on submit code
      document.getElementById("submit").disabled = true;
      document.getElementById("payment_method").display = false;
      document.getElementById("button-text").innerText = "PROCESSING...";

      // Use setTimeout to change innerText back to "CHECKOUT" after 5 seconds
      setTimeout(function () {
        document.getElementById("submit").disabled = false;
        document.getElementById("button-text").innerText = "COMPLETE CHECKOUT";
      }, 3000); // 3000 milliseconds = 3 seconds
    }

    if (storeContext.isActive !== "true") {
      toast.error(
        "Sorry we are closed right now. Please come back soon and try again."
      );
      return;
    }
    if (!orderData.Items) {
      return;
    }
    if (props.orderType === "Delivery") {
      if (orderData.OrderTotal < storeContext.deliveryMin) {
        toast.error(
          `Minimum Order amount for Delivery is $${storeContext.deliveryMin}. Please adjust your order and try again.`
        );
        return;
      }
      if (!props.selectedAddress) {
        toast.error("Please select an address for Delivery orders!");
        return;
      }
    }
    if (props.orderType === "Pickup") {
      if (storeContext.open !== "Open" && props.nowOrLater !== "Later") {
        toast.error(
          "We are not open for Pickup Orders right now. Please select a time for a Later Order."
        );
        return;
      }
    }
    if (props.orderType === "Delivery") {
      if (
        storeContext.deliveryOpen !== "Open" &&
        props.nowOrLater !== "Later"
      ) {
        toast.error(
          "We are not open for Delivery right now. Please select a time for a Later Order."
        );
        return;
      }
    }
    if (
      props.nowOrLater === "Later" &&
      (!props.selectedDay || !props.selectedTime)
    ) {
      toast.error(
        "Please make sure you have a selected day and time for future orders!"
      );
      return;
    }
    if (!props.orderType) {
      toast.error("Please select either Pickup or Delivery");
      return;
    }

    var orderETA;
    var paymentToken;
    var mxCustomerId;
    var paymentId;
    //get orderETA to send to db
    if (props.orderType === "Pickup") {
      orderETA = storeContext.pickupETA;
    } else if (props.orderType === "Delivery") {
      orderETA = storeContext.deliveryETA;
    }
    var newAppContext = {
      userInfo: appContext.userInfo,
      cart: null,
      cartItems: null,
    };

    if (props.customerOptsPayLater) {
      handleCheckout(orderETA);
    } else {
      if (!selectedPayment && (cardNumber && expMonth && expYear && cvcCode)) {
        //if card details are entered: charge the card
        var mxPaymentResult;
        var mxCustomerResult;
        var authCode;

        try {
          //send payment details to mx merchant
          mxPaymentResult = await authorizeMxPayment();
          mxPaymentResult = JSON.parse(mxPaymentResult.data.content);

          var errorData = {
            OrderID: newOrderId,
            Message:
              appContext.userInfo.phoneNumber +
              " - new card - " +
              appContext.userInfo.role +
              " " +
              mxPaymentResult.authMessage,
          };
          await axios.post(`${BASE_API}api/TraceLog/AddTracelog`, errorData, {
            headers: {
              "Content-Type": "application/json",
              Application: APPLICATION,
            },
          });
        } catch (error) {
          console.error("Error in payment authorization:", error);
        } //IF transaction is successful send order details to database (this is handled by handleCheckout)

        paymentToken = mxPaymentResult.paymentToken;
        authCode = mxPaymentResult.authCode;
        paymentId = mxPaymentResult.id;
        if (mxPaymentResult.status === "Approved") {
          //send order details to database
          await handleCheckout(orderETA);
          if (!appContext.userInfo.mxCustomerID) {
            //if they are NOT a CUSTOMER in mx merchant yet (so we do createNewMxCustomer)
            //create new customer in mx merchant
            mxCustomerResult = await createNewMxCustomer();
            mxCustomerResult = JSON.parse(mxCustomerResult.data.content);

            mxCustomerId = mxCustomerResult.id;
            mxCustomerId = mxCustomerId.toString();
            await updateCustomerID(mxCustomerId);

            await createNewMxVaultedPayment(mxCustomerId);
          } else {
            //else they ARE a CUSTOMER already in mx merchant (so we do NOT createNewMxCustomer)
            mxCustomerId = appContext.userInfo.mxCustomerID;

            await createNewMxVaultedPayment(mxCustomerId);
          }
          await updateOrderWithMxInfo(
            newOrderId,
            paymentToken,
            mxCustomerId,
            authCode,
            paymentId
          );
          await sendOrderConfirmationText(paymentId);
          props.setAppContext(newAppContext);
          localStorage.setItem("appContext", JSON.stringify(newAppContext));
        } else {
          if (mxPaymentResult.authMessage === "Invalid card number") {
            toast.error(
              "Payment Declined. Card Number error. Please check your card details and try again."
            );
          } else if (mxPaymentResult.risk.cvvMatch === false) {
            toast.error(
              "Payment Declined. CVC Code error. Please check your card details and try again."
            );
          } else if (mxPaymentResult.risk.avsZipMatch === false) {
            toast.error(
              "Payment Declined. Zip Code error. Please check your card details and try again."
            );
          } else if (mxPaymentResult.risk.avsAddressMatch === false) {
            toast.error(
              "Payment Declined. Billing Address error. Please check your card details and try again."
            );
          } else {
            toast.error(
              "Payment Declined. Please check your card details and try again."
            );
          }
          return;
        }
      } else if (selectedPayment) {
        //else the card details are NOT entered charge the selected payment method
        try {
          mxCustomerId = appContext.userInfo.mxCustomerID;
          var chargeVaultedPaymentResult = await authorizeChargeMxValuedPayment(
            selectedPayment.token
          );
          chargeVaultedPaymentResult = JSON.parse(
            chargeVaultedPaymentResult.data.content
          );

          var data = {
            OrderID: newOrderId,
            Message:
              appContext.userInfo.phoneNumber +
              " - payment profile - " +
              appContext.userInfo.role +
              " " +
              chargeVaultedPaymentResult.authMessage,
          };
          await axios.post(`${BASE_API}api/TraceLog/AddTracelog`, data, {
            headers: {
              "Content-Type": "application/json",
              Application: APPLICATION,
            },
          });
        } catch (error) {
          console.error("Error in vaulted payment authorization:", error);
        } //IF transaction is successful send order details to database (this is handled by handleCheckout)

        paymentToken = chargeVaultedPaymentResult.paymentToken;
        authCode = chargeVaultedPaymentResult.authCode;
        paymentId = chargeVaultedPaymentResult.id;

        if (chargeVaultedPaymentResult.status === "Approved") {
          //send order details to database
          await handleCheckout(orderETA);
          await updateOrderWithMxInfo(
            newOrderId,
            paymentToken,
            mxCustomerId,
            authCode,
            paymentId
          );
          await sendOrderConfirmationText(paymentId);
          props.setAppContext(newAppContext);
          localStorage.setItem("appContext", JSON.stringify(newAppContext));
        } else {
          if (
            chargeVaultedPaymentResult.authMessage === "Invalid card number"
          ) {
            toast.error(
              "Payment Declined. Card Number error. Please check your card details and try again."
            );
          } else if (chargeVaultedPaymentResult.risk.cvvMatch === false) {
            toast.error(
              "Payment Declined. CVC Code error. Please check your card details and try again."
            );
          } else if (chargeVaultedPaymentResult.risk.avsZipMatch === false) {
            toast.error(
              "Payment Declined. Zip Code error. Please check your card details and try again."
            );
          } else if (
            chargeVaultedPaymentResult.risk.avsAddressMatch === false
          ) {
            toast.error(
              "Payment Declined. Billing Address error. Please check your card details and try again."
            );
          } else {
            toast.error(
              "Payment Declined. Please check your card details and try again."
            );
          }
          return;
        }
      } else {
        toast.error(
          "Please select a payment method before proceeding to checkout!"
        );
      }
    }
  };

  async function sendOrderConfirmationText(id) {
    try {
      var mxData = {
        id: id,
        contact: appContext.userInfo.phoneNumber,
      };
      const dataresp = await axios.post(
        `${BASE_API}api/${isDemo}/send-payment-receipt`,
        JSON.stringify(mxData),
        {
          headers: {
            "Content-Type": "application/json",
            Application: APPLICATION,
          },
        }
      );

      return dataresp;
    } catch (err) {
      toast.error(err);
    }
  }

  async function authorizeChargeMxValuedPayment(paymentToken) {
    try {
      var mxData = {
        tenderType: "Card",
        cardAccount: {
          token: paymentToken,
        },
      };

      mxData.mike = captchaToken;

      if (storeContext.chargePercent) {
        mxData.amount = props.discountedTotal;
      } else {
        mxData.amount = props.finalTotal;
      }

      const dataresp = await axios.post(
        `${BASE_API}api/${isDemo}/authorize-mxmerchant-vaultedpayment`,
        JSON.stringify(mxData),
        {
          headers: {
            "Content-Type": "application/json",
            Application: APPLICATION,
            // "X-CSRF-TOKEN": csrfToken, // Send the CSRF token in the request header
            // "Session-Id": sessionId,
          },
        }
      );

      resetCaptcha();
      return dataresp;
    } catch (err) {
      toast.error(err);
    }
  }

  async function createNewMxVaultedPayment(customerId) {
    try {
      var mxData = {
        code: "Card",
        isDefault: true,
        number: cardNumber,
        expiryMonth: expMonth,
        expiryYear: expYear,
        cvv: cvcCode,
        alias: "CustomerCardAccount",
        id: customerId,
      };

      const dataresp = await axios.post(
        `${BASE_API}api/${isDemo}/create-mxmerchant-customer-vaultedpayment`,
        JSON.stringify(mxData),
        {
          headers: {
            "Content-Type": "application/json",
            Application: APPLICATION,
          },
        }
      );

      return dataresp;
    } catch (err) {
      toast.error(err);
    }
  }

  async function authorizeMxPayment() {
    try {
      var mxData = {
        tenderType: "Card",
        cardAccount: {
          number: cardNumber,
          expiryMonth: expMonth,
          expiryYear: expYear,
          cvv: cvcCode,
        },
      };

      mxData.mike = captchaToken;

      if (storeContext.chargePercent) {
        mxData.amount = props.discountedTotal;
      } else {
        mxData.amount = props.finalTotal;
      }
      const dataresp = await axios.post(
        `${BASE_API}api/${isDemo}/authorize-mxmerchant-payment`,
        JSON.stringify(mxData),
        {
          headers: {
            "Content-Type": "application/json",
            Application: APPLICATION,
            // "X-CSRF-TOKEN": csrfToken, // Send the CSRF token in the request header
            // "Session-Id": sessionId,
          },
        }
      );

      resetCaptcha();
      return dataresp;
    } catch (err) {
      toast.error(err);
    }
  }

  async function updateOrderWithMxInfo(
    orderId,
    paymentToken,
    mxCustomerId,
    authCode,
    paymentId
  ) {
    try {
      var mxData = {
        OrderID: orderId,
        MxCustomerID: mxCustomerId,
        PaymentToken: paymentToken,
        AuthCode: authCode,
        PaymentID: paymentId,
      };
      await axios.put(`${BASE_API}api/Order/UpdateOrdersMxDetails`, mxData, {
        headers: {
          Authorization: `Bearer ${appContext.userInfo.token}`,
          "Content-Type": "application/json",
          Application: APPLICATION,
        },
      });
    } catch (err) {
      toast.error(err);
    }
  }

  async function createNewMxCustomer() {
    try {
      var mxData = {
        tenderType: "Card",
        email: appContext.userInfo.email,
        phone: appContext.userInfo.phoneNumber,
      };
      if (
        appContext.userInfo.role === "GuestUser" ||
        appContext.userInfo.role === "Guest"
      ) {
        mxData.name = appContext.userInfo.firstName;
        mxData.firstName = appContext.userInfo.firstName;
      } else {
        mxData.name =
          appContext.userInfo.firstName + " " + appContext.userInfo.lastName;
        mxData.firstName = appContext.userInfo.firstName;
        mxData.lastName = appContext.userInfo.lastName;
      }

      const dataresp = await axios.post(
        `${BASE_API}api/${isDemo}/create-mxmerchant-customer`,
        JSON.stringify(mxData),
        {
          headers: {
            "Content-Type": "application/json",
            Application: APPLICATION,
          },
        }
      );

      return dataresp;
    } catch (err) {
      toast.error(err);
    }
  }

  async function updateCustomerID(profileID) {
    try {
      var newUser;
      if (
        appContext.userInfo.role === "GuestUser" ||
        appContext.userInfo.role === "Guest"
      ) {
        newUser = appContext.userInfo;
        newUser.mxCustomerID = profileID;
      } else {
        newUser = appContext.userInfo;
        newUser.mxCustomerID = profileID;
        await axios.put(`${BASE_API}api/User/UpdateMxCustomerID`, profileID, {
          headers: {
            Authorization: `Bearer ${appContext.userInfo.token}`,
            "Content-Type": "application/json",
            Application: APPLICATION,
          },
        });
      }
      var newAppContext = {
        userInfo: newUser,
        cart: appContext.cart,
        cartItems: appContext.cartItems,
      };
      props.setAppContext(newAppContext);
      localStorage.setItem("appContext", JSON.stringify(newAppContext));
    } catch (err) {
      toast.error("Failed to create Customer ID");
    }
  }

  async function handleCheckout(orderETA) {
    var pointsEarnedThisOrder = 0;
    var newPointsBalance = 0;
    var userPointsData = {};
    var newContext = {};
    var newUserInfo = {};
    var newAppContext = {};
    var total;
    try {
      var utcISOString = getNowTime();

      orderData.OrderType = props.orderType;
      orderData.OrderTip = props.tipAmount.amount;
      orderData.OrderedDate = utcISOString;
      orderData.OrderETA = orderETA;
      orderData.DeliveryFee = props.deliveryFee;
      if (props.customerOptsPayLater) {
        orderData.PayLater = "true";
        orderData.PercentageFee = 0;
      } else {
        orderData.PayLater = "";
        orderData.PercentageFee = props.isCashAdjustment;
      }
      if (props.nowOrLater === "Later") {
        const utcDate = props.selectedDay.mysqlDate.split("T")[0];
        const utcTime = props.selectedTime.est;
        orderData.RequestedDate = utcDate + "T" + utcTime;
      } else {
        orderData.RequestedDate = utcISOString;
      }
      if (storeContext.chargePercent) {
        orderData.DiscountedChargePercent = storeContext.chargePercent;
      }
      if (props.orderType === "Delivery") {
        orderData.OrderAddress = props.selectedAddress.residenceID;
        orderData.StreetAddress =
          props.selectedAddress.streetAddress +
          ", " +
          props.selectedAddress.city +
          " " +
          props.selectedAddress.stateName +
          " " +
          props.selectedAddress.zipCode;
      }
      if (
        appContext.userInfo.role === "GuestUser" ||
        appContext.userInfo.role === "Guest"
      ) {
        orderData.Name = appContext.userInfo.firstName;
      } else if (
        appContext.userInfo.role === "Admin" ||
        appContext.userInfo.role === "User"
      ) {
        orderData.Name =
          appContext.userInfo.firstName + " " + appContext.userInfo.lastName;
      } else {
        orderData.Name = "-";
      }

      var orderDataJson = JSON.stringify(orderData);
      await axios.post(`${BASE_API}api/Order/NewOrder`, orderDataJson, {
        headers: {
          Authorization: `Bearer ${appContext.userInfo.token}`,
          "Content-Type": "application/json",
          Application: APPLICATION,
        },
      });
      toast.success("Order Success");

      //if the store has a rewards program
      if (storeContext.rewardsProgram === "true") {
        //if it is a user update their reward points
        if (
          appContext.userInfo.role === "User" ||
          appContext.userInfo.role === "Admin"
        ) {
          if (orderData.DiscountedTotal) {
            pointsEarnedThisOrder = parseFloat(
              orderData.DiscountedTotal.toFixed(0)
            );
          } else {
            if (!orderData.PercentageFee) {
              total =
                orderData.OrderSubtotal +
                orderData.OrderTax +
                orderData.OrderTip +
                orderData.Fee;
            } else {
              total =
                orderData.OrderSubtotal +
                orderData.OrderTax +
                orderData.OrderTip +
                orderData.Fee +
                orderData.PercentageFee;
            }
            if (props.orderType === "Delivery") {
              total = total + orderData.DeliveryFee;
            }
            total = parseFloat(total.toFixed(0));
            pointsEarnedThisOrder = total;
          }

          const reward_points = appContext.userInfo?.rewardPoints ?? 0;
          newPointsBalance = reward_points + pointsEarnedThisOrder;

          userPointsData = {
            rewardPoints: newPointsBalance,
            userID: appContext.userInfo.userID,
          };
          await axios.put(
            `${BASE_API}UpdateCustomerRewardsPoints`,
            userPointsData,
            {
              headers: {
                Authorization: `Bearer ${appContext.userInfo.token}`,
                Application: APPLICATION,
              },
            }
          );

          newUserInfo = appContext.userInfo;
          newUserInfo.rewardPoints = parseFloat(newPointsBalance);

          newAppContext = {
            userInfo: newUserInfo,
            cart: null,
            cartItems: null,
          };
          props.setAppContext(newAppContext);
          localStorage.setItem("appContext", JSON.stringify(newAppContext));
        } else {
          newContext = {
            userInfo: appContext.userInfo,
            cart: null,
            cartItems: null,
          };
          props.setAppContext(newContext);
          localStorage.setItem("appContext", JSON.stringify(newContext));
        }
      } else {
        newContext = {
          userInfo: appContext.userInfo,
          cart: null,
          cartItems: null,
        };
        props.setAppContext(newContext);
        localStorage.setItem("appContext", JSON.stringify(newContext));
      }

      navigate(`/${STORE_NAME_CODE}/completion`);
    } catch (error) {
      var d = JSON.stringify(orderData);
      var data = {};
      if (error.config) {
        data = {
          OrderID: newOrderId,
          Message:
            error.message +
            " " +
            error.config.data +
            " " +
            error.config.url +
            " - " +
            d,
        };
      } else {
        data = {
          OrderID: newOrderId,
          Message: error.message + " - " + d,
        };
      }
      await axios.post(`${BASE_API}api/TraceLog/AddTracelog`, data, {
        headers: {
          "Content-Type": "application/json",
          Application: APPLICATION,
        },
      });
      await axios.post(`${BASE_API}api/Order/NewOrder`, d, {
        headers: {
          Authorization: `Bearer ${appContext.userInfo.token}`,
          "Content-Type": "application/json",
          Application: APPLICATION,
        },
      });

      //if the store has a rewards program
      if (storeContext.rewardsProgram === "true") {
        //if it is a user update their reward points
        if (
          appContext.userInfo.role === "User" ||
          appContext.userInfo.role === "Admin"
        ) {
          if (orderData.DiscountedTotal) {
            pointsEarnedThisOrder = parseFloat(
              orderData.DiscountedTotal.toFixed(0)
            );
          } else {
            if (!orderData.PercentageFee) {
              total =
                orderData.OrderSubtotal +
                orderData.OrderTax +
                orderData.OrderTip +
                orderData.Fee;
            } else {
              total =
                orderData.OrderSubtotal +
                orderData.OrderTax +
                orderData.OrderTip +
                orderData.Fee +
                orderData.PercentageFee;
            }
            if (props.orderType === "Delivery") {
              total = total + orderData.DeliveryFee;
            }
            total = parseFloat(total.toFixed(0));
            pointsEarnedThisOrder = total;
          }

          const reward_points = appContext.userInfo?.rewardPoints ?? 0;
          newPointsBalance = reward_points + pointsEarnedThisOrder;

          userPointsData = {
            rewardPoints: newPointsBalance,
            userID: appContext.userInfo.userID,
          };
          await axios.put(
            `${BASE_API}UpdateCustomerRewardsPoints`,
            userPointsData,
            {
              headers: {
                Authorization: `Bearer ${appContext.userInfo.token}`,
                Application: APPLICATION,
              },
            }
          );

          newUserInfo = appContext.userInfo;
          newUserInfo.rewardPoints = parseFloat(newPointsBalance);

          newAppContext = {
            userInfo: newUserInfo,
            cart: null,
            cartItems: null,
          };
          props.setAppContext(newAppContext);
          localStorage.setItem("appContext", JSON.stringify(newAppContext));
        } else {
          newContext = {
            userInfo: appContext.userInfo,
            cart: null,
            cartItems: null,
          };
          props.setAppContext(newContext);
          localStorage.setItem("appContext", JSON.stringify(newContext));
        }
      } else {
        newContext = {
          userInfo: appContext.userInfo,
          cart: null,
          cartItems: null,
        };
        props.setAppContext(newContext);
        localStorage.setItem("appContext", JSON.stringify(newContext));
      }
    }
  }

  async function setPaymentProfileForOrder(i) {
    if (!isAddingPaymentMethod) {
      var activeProfile = document.querySelector(".payment_profile.Active");
      if (activeProfile) {
        activeProfile.classList.remove("Active");
        setSelectedPayment();
      } else {
        var profileSelected = document.getElementById(
          `payment_profile_${i.cardId}`
        );
        profileSelected.classList.add("Active");
        setSelectedPayment(i);
      }
    } else {
      toast.error(
        "Please close the add a new payment method to select a saved payment method."
      );
    }
  }

  async function addPaymentMethod() {
    if (isAddingPaymentMethod === true) {
      if (previouslyActivePayment) {
        setSelectedPayment(previouslyActivePayment);
        var profileSelected = document.querySelector(
          `.payment_profile_${previouslyActivePayment.cardId}`
        );
        if (profileSelected) {
          profileSelected.classList.add("Active");
        }
      }
    } else {
      var activeProfile = document.querySelector(".payment_profile.Active");
      if (activeProfile) {
        activeProfile.classList.remove("Active");
      }
      if (props.customerPaymentProfiles.recordCount === 1) {
        setPreviouslyActivePayment(props.customerPaymentProfiles.records[0]);
      } else if (props.customerPaymentProfiles.recordCount > 1) {
        setPreviouslyActivePayment(selectedPayment);
      }
      setSelectedPayment();
    }
    setIsAddingPaymentMethod(!isAddingPaymentMethod);
  }

  async function invalidItemsSubmit() {
    toast.error(
      "You have items that are invalid to order at the selected time. Please check your cart and try again!"
    );
  }

  async function togglePayLater() {
    props.setCustomerOptsPayLater(!props.customerOptsPayLater);
  }

  return (
    <div className="card m-1 checkout" id="payment_method">
      <div className="card-header">
        <div className="orderPage_header">Choose a payment method</div>
      </div>
      <div className="card-body">
        {storeContext.payLater && (
          <>
            <div className="add_payment_method">
              <div className="add_paymentMethodTitle">
                Pay at the time of{" "}
                {props.orderType !== "Pickup" &&
                  props.orderType !== "Delivery" ? (
                  <>Pickup/Delivery</>
                ) : (
                  <>
                    {props.orderType === "Pickup" && <>Pickup</>}
                    {props.orderType === "Delivery" && <>Delivery</>}
                  </>
                )}
              </div>
              {storeContext.nameCode === "brendaleedover" && (
                <div className="cash_only">
                  <>Cash Only</>
                </div>
              )}
              <input
                className="pay_later_checkbox"
                type="checkbox"
                checked={props.customerOptsPayLater}
                onClick={togglePayLater}
              />
            </div>
          </>
        )}
        {!props.customerOptsPayLater && (
          <>
            <div className="add_payment_method" onClick={addPaymentMethod}>
              <div className="add_paymentMethodTitle">
                Add new payment method
              </div>
              <AiFillPlusCircle />
            </div>

            {isAddingPaymentMethod &&
              props.customerPaymentProfiles &&
              props.customerPaymentProfiles.recordCount > 0 && (
                <form>
                  <MxAddPaymentMethod
                    setCardNumber={setCardNumber}
                    setExpMonth={setExpMonth}
                    setExpYear={setExpYear}
                    setCvcCode={setCvcCode}
                    processor={"MxMerchant"}
                  />
                </form>
              )}
            {props.customerPaymentProfiles &&
              props.customerPaymentProfiles.length === 0 ? (
              <form>
                <MxAddPaymentMethod
                  setCardNumber={setCardNumber}
                  setExpMonth={setExpMonth}
                  setExpYear={setExpYear}
                  setCvcCode={setCvcCode}
                  processor={"MxMerchant"}
                />
              </form>
            ) : (
              <>
                {props.customerPaymentProfiles &&
                  props.customerPaymentProfiles.recordCount === 1 ? (
                  <div
                    className={`payment_profile payment_profile_${props.customerPaymentProfiles.records[0].cardId} Active`}
                  >
                    <div className="card_type">
                      {props.customerPaymentProfiles.records[0].cardType}
                    </div>
                    <div className="card_number">
                      {props.customerPaymentProfiles.records[0].last4}
                    </div>
                  </div>
                ) : (
                  <>
                    {props.customerPaymentProfiles &&
                      props.customerPaymentProfiles.recordCount > 0 && (
                        <>
                          {props.customerPaymentProfiles.records.map(
                            (i, index) => (
                              <div
                                key={index}
                                className="payment_profile"
                                id={`payment_profile_${i.cardId}`}
                                onClick={() => setPaymentProfileForOrder(i)}
                              >
                                <div className="card_type">{i.cardType}</div>
                                <div className="card_number">{i.last4}</div>
                              </div>
                            )
                          )}
                        </>
                      )}
                  </>
                )}
              </>
            )}
          </>
        )}

        <div className="d-flex justify-content-center mt-3">
          <ReCAPTCHA
            sitekey="6Lcd7YQqAAAAALmui5JnWvTjD8jYBbtsSqJ6ndi-" // Replace with your site key from Google
            ref={captchaRef}
            onChange={handleCaptchaChange}
          />
        </div>

        {props.invalidDayItems ||
          props.invalidTypeItems ||
          props.invalidTimeItems ||
          props.invalidPayInStore ? (
          <button id="submit" className="invalid" onClick={invalidItemsSubmit}>
            <span id="button-text">COMPLETE CHECKOUT</span>
          </button>
        ) : (
          <button id="submit" onClick={handleSubmit}>
            <span id="button-text">COMPLETE CHECKOUT</span>
          </button>
        )}
      </div>
    </div>
  );
}

export default MxCheckoutForm;
