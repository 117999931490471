import React, { useState, useContext, useEffect } from "react";
import { HiMenu } from "react-icons/hi";
import "./navbar.scss";
import ApplicationContext from "../../ApplicationContext";
import {
  DISCOUNT_PERCENTAGE,
  HOME_REDIRECT,
  STORE_NAME,
  STORE_NAME_CODE,
} from "../../constants";
import StoreContext from "../../StoreContext";
import HoursModal from "../hours/HoursModal";
import LocationModal from "../location/LocationModal";
import ImageGalleryModal from "../imageGalleryModal/ImageGalleryModal";

function Navbar(props) {
  const storeContext = useContext(StoreContext);
  const appContext = useContext(ApplicationContext);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showHoursModal, setShowHoursModal] = useState(false);
  const [showImageGalleryModal, setShowImageGalleryModal] = useState(false);
  const [showLocationModal, setShowLocationModal] = useState(false);

  useEffect(() => {
    if (document.querySelector(".store_is_not_active")) {
      document.querySelector(".navbar").classList.add("store-inactive");
    }

    const element = document.getElementById("topbar");
    const element2 = document.getElementById("dropdown");
    const element4 = document.getElementById("welcome");

    const alphaValue = 0.5;
    // Parse the original color string to extract the RGB values
    const match = storeContext.color.match(/\d+/g);
    let modifiedLightColor;
    if (match && match.length === 3) {
      const [red, green, blue] = match;
      // Create the new color string with the alpha value
      modifiedLightColor = `rgba(${red}, ${green}, ${blue}, ${alphaValue})`;
    }

    if (element && storeContext.color) {
      element.style.backgroundColor = storeContext.color;
    }
    if (element2 && storeContext.color) {
      element2.style.backgroundColor = storeContext.color;
    }
    if (element4 && modifiedLightColor) {
      element4.style.backgroundColor = modifiedLightColor;
    }
  }, [storeContext]);

  async function dropdown() {
    setShowDropdown(!showDropdown);
  }

  async function showAuthModal(keyIn) {
    props.setAuthKey(keyIn);
    props.setShowAuthenticateModal(true);
  }

  function logoutHandler() {
    var newAppContext = {
      userInfo: null,
      cart: null,
      cartItems: null,
    };
    props.setAppContext(newAppContext);
    localStorage.setItem("appContext", JSON.stringify(newAppContext));
    setShowDropdown(false);
    window.location.replace(HOME_REDIRECT + `${STORE_NAME_CODE}`);
  }

  async function toggleHoursModal() {
    if (storeContext.hours) {
      setShowHoursModal(true);
    }
  }
  async function toggleImageGalleryModal() {
    if (storeContext.storeImages.length > 0) {
      setShowImageGalleryModal(true);
    }
  }
  async function toggleLocationModal() {
    setShowLocationModal(true);
  }

  return (
    <>
      <header className="navbar">
        <div className="topbar" id="topbar">
          <div className="left">
            <a href={`/${STORE_NAME_CODE}`}>
              <h1 className="store_name">{STORE_NAME}</h1>
            </a>
          </div>
          <div className="right">
            <HiMenu onClick={dropdown} />
            <div className="dropdown" id="dropdown">
              {showDropdown ? (
                <ul className="container-fluid list-unstyled mb-2">
                  {appContext.userInfo &&
                    appContext.userInfo.role === "Admin" && (
                      <a
                        className="nav_item py-3 border-bottom border-top"
                        href={`/${STORE_NAME_CODE}/admin`}
                      >
                        Admin Portal
                      </a>
                    )}
                  <li
                    className="nav_item py-3 border-bottom"
                    onClick={() => toggleHoursModal()}
                  >
                    Hours
                  </li>
                  
                  {/* <li
                    className="nav_item py-3 border-bottom"
                    onClick={() => toggleImageGalleryModal()}
                  >
                    Image Gallery
                  </li> */}
                  {appContext.userInfo &&
                    (appContext.userInfo.role === "User" ||
                      appContext.userInfo.role === "Admin") ? (
                    <>
                      <a
                        className="nav_item py-3 border-bottom"
                        href={`/${STORE_NAME_CODE}/account`}
                      >
                        Account
                      </a>

                      <li
                        className="nav_item py-3 border-bottom"
                        onClick={logoutHandler}
                      >
                        Logout
                      </li>
                    </>
                  ) : (
                    <>
                      <li
                        className="nav_item py-3 border-bottom"
                        onClick={() => showAuthModal("Login")}
                      >
                        Login
                      </li>
                      <li
                        className="nav_item py-3 border-bottom"
                        onClick={() => showAuthModal("Register")}
                      >
                        Register
                      </li>
                    </>
                  )}
                  <a className="nav_item py-3 border-bottom" href={`/`}>
                    More Stores
                  </a>
                </ul>
              ) : null}
            </div>
          </div>
        </div>
        <div
          className="welcome w-100 d-flex flex-column justify-content-center"
          id="welcome"
        >
          {props.showWelcome && (
            <>
              <div className="welcome_image_container d-flex justify-content-center pt-1">
                <img
                  className="image mb-1"
                  src={`./pictures/${STORE_NAME_CODE}.png`}
                  alt=""
                />
              </div>
              <div className="card address_container d-flex flex-column p-2 mb-2">
                <h2 className="store_address">{storeContext.address}</h2>
              </div>
              {DISCOUNT_PERCENTAGE && (
                <div className="card discount d-flex flex-column p-2 mb-2">
                  <div className="d-flex justify-content-center message">
                    🎉{" "}
                    <b className="mx-1">
                      Enjoy {DISCOUNT_PERCENTAGE} Off All Orders
                    </b>{" "}
                    🎉
                  </div>
                  {props.discountEndDate && (
                    <div className="d-flex justify-content-center endDate">
                      Offer Ends:{" "}
                      <b className="mx-1"> {props.discountEndDate}</b>
                    </div>
                  )}
                  <div className="d-flex justify-content-center endDate">
                    Discount Applied at Checkout
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </header>
      {showHoursModal ? (
        <HoursModal
          showHoursModal={showHoursModal}
          setShowHoursModal={setShowHoursModal}
        />
      ) : null}
      {/* {showImageGalleryModal && (
        <ImageGalleryModal
          appContext={appContext}
          showImageGalleryModal={showImageGalleryModal}
          setShowImageGalleryModal={setShowImageGalleryModal}
        />
      )} */}
      {showLocationModal ? (
        <LocationModal
          showLocationModal={showLocationModal}
          setShowLocationModal={setShowLocationModal}
        />
      ) : null}
    </>
  );
}

export default Navbar;
