// APPLICATION (for api calls to determine correct database)
export let APPLICATION = "";
export const setApplication = (storeParam) => {
  APPLICATION = storeParam;
};

// STORE DETAILS
export let ORDER_FEE = 0;
export let HOURS = "";
export let IS_OPEN = "";
export let STORE_NAME = "";
export let STORE_NAME_CODE = "";
export let TAX_PERCENTAGE = 0;
export let DISCOUNT_PERCENTAGE = "";
export let CATEGORY_NAME = "";
export let SUB_CATEGORY_NAME = "";
export const setStore = (
  orderFee,
  hours,
  open,
  name,
  nameCode,
  tax,
  discountPercent,
  categoryName,
  subCategoryName,
) => {
  ORDER_FEE = orderFee;
  HOURS = hours;
  IS_OPEN = open;
  STORE_NAME = name;
  STORE_NAME_CODE = nameCode;
  TAX_PERCENTAGE = tax;
  DISCOUNT_PERCENTAGE = discountPercent;
  CATEGORY_NAME = categoryName;
  SUB_CATEGORY_NAME = subCategoryName;
};

export const BASE_API = "https://myorderportalservice.com/";
export const HOME_REDIRECT = "https://myorderportal.com/";

// export const BASE_API = "http://192.168.1.156:8080/";

// export const BASE_API = "http://192.168.1.156:8080/";
// export const BASE_API = "https://localhost:5001/";
// export const HOME_REDIRECT = "http://localhost:3000/";

export const IS_DEMO = "MxMerchant";
export const IS_DEMO_DEMO = "MxMerchantDemo";

// export const IS_DEMO = "AuthorizeNet";
// export const IS_DEMO_DEMO = "AuthorizeNetDemo";


