import React, { useContext } from "react";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  EmailIcon
} from 'react-share';
import StoreContext from '../../StoreContext';

const styles = {
  container: {
    display: 'flex',
    gap: '12px',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px 0',
  },
  button: {
    transition: 'transform 0.2s ease',
    cursor: 'pointer',
  },
  buttonHover: {
    transform: 'scale(1.1)'
  }
};

const ShareButtons = () => {
  const storeContext = useContext(StoreContext);

  const url = window.location.href.replace(/\/completion$/, '');
  const title = `Order online from ${storeContext.name}!`;
  const description = `I just placed an order from ${window.location.href.split('/')[3]}! Come check out their delicious menu and order online.`;

  return (
    <div style={{marginTop: '1.5em'}}>
        <h2 style={{
          textAlign: 'center',
          fontSize: '1.5rem',
          fontWeight: 'bold',
          margin: '15px 0',
          color: '#333',
          letterSpacing: '1px'
        }}>
          Share With Friends & Family!
        </h2>
    
        <div style={styles.container}>
        <FacebookShareButton 
            url={url} 
            quote={description}
            style={styles.button}
            hashtags={['#order', '#delivery', '#food', '#online-ordering']}
        >
            <FacebookIcon size={32} round />
        </FacebookShareButton>

        <WhatsappShareButton 
            url={url} 
            title={title}
            style={styles.button}
        >
            <WhatsappIcon size={32} round />
        </WhatsappShareButton>

        <TwitterShareButton 
            url={url} 
            title={title}
            style={styles.button}
        >
            <TwitterIcon size={32} round />
        </TwitterShareButton>

        <EmailShareButton 
            url={url} 
            subject={title} 
            body={description}
            style={styles.button}
        >
            <EmailIcon size={32} round />
        </EmailShareButton>
        </div>
    </div>
    
    
  );
};

export default ShareButtons;
